const location: object[] = [
  "Adams",
  "Allegheny",
  "Armstrong",
  "Beaver",
  "Bedford",
  "Berks",
  "Blair",
  "Bradford",
  "Bucks",
  "Butler",
  "Cambria",
  "Cambria ",
  "Carbon",
  "Centre",
  "Chester",
  "Clarion",
  "Clearfield",
  "Clinton",
  "Columbia",
  "Crawford",
  "Cumberland",
  "Dauphin",
  "Delaware",
  "Elk",
  "Erie",
  "Fayette",
  "Forest",
  "Franklin",
  "Fulton",
  "Greene",
  "Huntingdon",
  "Indiana",
  "Jefferson",
  "Juniata",
  "Lackawanna",
  "Lancaster",
  "Lawrence",
  "Lebanon",
  "Lehigh",
  "Luzerne",
  "Lycoming",
  "McKean",
  "Mercer",
  "Mifflin",
  "Monroe",
  "Montgomery",
  "Montour",
  "Northampton",
  "Northumberland",
  "Perry",
  "Philadelphia",
  "Pike",
  "Potter",
  "Schuylkill",
  "Snyder",
  "Somerset",
  "Sullivan",
  "Susquehanna",
  "Tioga",
  "Union",
  "Venango",
  "Warren",
  "Washington",
  "Wayne",
  "Westmoreland",
  "Wyoming",
  "York",
  "Statewide"
].map(item => ({
  value: item,
  label: item
}));

export default location;
